<template>
    <BaseButton
        v-if="dossier && user"
        :icon="isFollowed ? 'check' : 'plus'"
        :class="{
            'base-button--secondary' : !isFollowed
        }"
        :disabled="isSaving"
        :title="isFollowed ? `Je ontvangt updates over ${ dossier.title }` : `Ontvang updates over ${dossier.title}`"
        @click="toggleDossierFollow"
    >
        <slot :is-followed="isFollowed">
            {{ dossier.title }}
        </slot>
    </BaseButton>
</template>

<script setup>
import { DOSSIER_FREQUENCY_WEEKLY } from '~/constants/dossier-frequencies';
import { useAuthStore } from '~/store/auth';
import { useDossierStore } from '~/store/dossiers';
import { useModalStore } from '~/store/modal';

const props = defineProps({
    dossierId: {
        type: [String, Number],
        default: null,
    }
});

const isSaving = ref(false);

const user = computed(() => {
    return useAuthStore().userModel;
});

const dossier = computed(() => {
    return useDossierStore().dossierById(props.dossierId);
});

const isFollowed = computed(() => {
    if (!props.dossierId) {
        return false;
    }

    return toValue(user).isFollowingDossier(props.dossierId);
});

const toggleDossierFollow = function() {
    if (!toValue(isFollowed)) {
        return followDossier();
    }

    const confirmed = confirm('Weet je zeker dat je dit dossier niet meer wilt volgen?');

    if (!confirmed) {
        return;
    }

    unfollowDossier();
};

const followDossier = async() => {
    const userModel = toValue(user);

    isSaving.value = true;
    await userModel.followDossiers([props.dossierId]);
    isSaving.value = false;

    if (!userModel.dossierNotificationFrequency) {
        await userModel.setDossierUpdateFrequency(DOSSIER_FREQUENCY_WEEKLY);

        useModalStore().open('dossier-update-frequency');
    }
};

const unfollowDossier = async() => {
    isSaving.value = true;
    await toValue(user).unfollowDossiers([props.dossierId]);
    isSaving.value = false;
};
</script>

<style lang="less" src="./DossierFollowButton.less"></style>
